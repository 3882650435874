import toast from 'react-hot-toast';
import { introjs } from '../IntroJs/Steps';

export const productSOHSteps = selectedFleet => {
  if (selectedFleet.pl !== '3') {
    return [
      {
        step: 0,
        element: '[data-intro="site-name"]',
        intro: 'Welcome to Condition Monitoring for Traction.',
      },

      {
        step: 1,
        element: `[data-intro="${selectedFleet.nm}"]`,
        intro: '',
      },
      {
        step: 2,
        element: `[data-intro="${selectedFleet.nm}"]`,
        intro:
          'Click on a fleet to explore how your traction batteries are operating.',
      },
      {
        step: 3,
        element: '[data-intro=""]',
        intro:
          'The main page provides a view on your batteries recent operation.',
      },
      {
        step: 4,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 5,
        element: '[data-intro="vehicle_num_click_0"]',
        intro:
          'Click on a vehicle to open the vehicle view and expand the list of installed batteries.',
      },
      {
        step: 6,
        element: '[data-intro="vehicle_product_00"]',
        intro:
          'Click on a battery to see its latest operational status, as well as information on short and longterm operation and overall health.',
      },
      {
        step: 7,
        element: '[data-intro="vehicle_product_00"]',
        intro: '',
      },
      {
        step: 8,
        element: '[data-intro=""]',
        intro:
          'Here you can see the overview of the product and its operation.',
      },
      {
        step: 9,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 10,
        element: '[data-intro="state_of_health_title"]',
        intro:
          'Click here see the batteries State of Health evolution, since its installation.',
      },
      {
        step: 11,
        element: '[data-intro="single_graph"]',
        intro:
          'The State of Health graph shows the overall traction batterys performance against its design.',
      },
      {
        step: 12,
        element: '[data-intro="single_graph"]',
        intro:
          "The blue dashed line shows the expected gradual degradation of the battery's performance as it reaches its end of life.",
      },
      {
        step: 13,
        element: '[data-intro="single_graph"]',
        intro:
          'The purple dotted line shows the current estimated degradation.',
      },
      {
        step: 14,
        element: '[data-intro=""]',

        intro: `That's it for the traction battery SoH guide. For more information, you can refer glossary or other user guides.`,
      },
    ];
  } else {
    return [
      {
        step: 0,
        element: '[data-intro="site-name"]',
        intro: 'Welcome to Condition Monitoring for Traction.',
      },

      {
        step: 1,
        element: `[data-intro="${selectedFleet.nm}"]`,
        intro: '',
      },
      {
        step: 2,
        element: `[data-intro="${selectedFleet.nm}"]`,
        intro:
          'Click on a fleet to explore how your traction batteries are operating.',
      },
      {
        step: 3,
        element: '[data-intro=""]',
        intro:
          'The main page provides a view on your batteries recent operation.',
      },
      {
        step: 4,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 5,
        element: '[data-intro="vehicle_num_click_0"]',
        intro:
          'Click on a vehicle to open the vehicle view and expand the list of installed batteries.',
      },
      {
        step: 6,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 7,
        element: '[data-intro="battery_num_click_0"]',
        intro:
          'Click on a battery to see its latest operational status, as well as information on short and longterm operation and overall health.',
      },
      {
        step: 8,
        element: '[data-intro=""]',
        intro:
          'Here you can see the overview of the battery and its operation.',
      },
      {
        step: 9,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 10,
        element: '[data-intro="string_num_0"]',
        intro:
          'Click on a product to see its latest operational status, as well as information on short and long term operation, overall health. For more information on the product-level graphs, refer to other available user guides.',
      },
      {
        step: 11,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 12,
        element: '[data-intro="state_of_health_title"]',
        intro:
          'Click here see the batteries State of Health evolution, since its installation.',
      },
      {
        step: 13,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 14,
        element: '[data-intro="single_graph"]',
        intro:
          'The State of Health graph shows the overall traction batterys performance against its design.',
      },
      {
        step: 15,
        element: '[data-intro="single_graph"]',
        intro:
          "The blue dashed line shows the expected gradual degradation of the battery's performance as it reaches its end of life.",
      },
      {
        step: 16,
        element: '[data-intro="single_graph"]',
        intro:
          'The purple dotted line shows the current estimated degradation.',
      },
      {
        step: 17,
        element: '[data-intro=""]',

        intro: `That's it for the traction battery SoH guide. For more information, you can see the online help or other user guides.`,
      },
    ];
  }
};

export const productSOHStepChange = (step, selectedFleet) => {
  try {
    if (selectedFleet.pl !== '3') {
      if (step === 0) {
        document.querySelector('[data-intro="site-name"]').click();
      }
      if (step === 1) {
        introjs.goToStep(2);
        introjs.start();
      }
      if (step === 3) {
        document.querySelector(`[data-intro="${selectedFleet.nm}"]`).click();
      }
      if (step === 4) {
        introjs.goToStep(5);
        introjs.start();
      }
      if (step === 6) {
        document.querySelector('[data-intro="vehicle_num_click_0"]').click();
      }
      if (step === 7) {
        introjs.goToStep(8);
        introjs.start();
      }
      if (step === 8) {
        document.querySelector('[data-intro="vehicle_product_00"]').click();
      }
      if (step === 9) {
        introjs.goToStep(10);
        introjs.start();
      }
      if (step === 10) {
        document.querySelector('[data-intro="state_of_health_title"]').click();
      }
      if (step === 14) {
        document.querySelector('[data-intro="site-name"]').click();
      }
    } else {
      if (step === 0) {
        document.querySelector('[data-intro="site-name"]').click();
      }
      if (step === 1) {
        introjs.goToStep(2);
        introjs.start();
      }
      if (step === 3) {
        document.querySelector(`[data-intro="${selectedFleet.nm}"]`).click();
      }
      if (step === 4) {
        introjs.goToStep(5);
        introjs.start();
      }
      if (step === 5) {
        document.querySelector('[data-intro="vehicle_num_click_0"]').click();
      }
      if (step === 6) {
        introjs.goToStep(7);
        introjs.start();
      }
      if (step === 8) {
        document.querySelector('[data-intro="battery_num_click_0"]').click();
      }
      if (step === 9) {
        introjs.goToStep(10);
        introjs.start();
      }
      if (step === 10) {
        document.querySelector('[data-intro="string_num_0"]').click();
      }
      if (step === 11) {
        introjs.goToStep(12);
        introjs.start();
      }
      if (step === 12) {
        document.querySelector('[data-intro="state_of_health_title"]').click();
      }
      if (step === 13) {
        introjs.goToStep(14);
        introjs.start();
      }

      if (step === 17) {
        document.querySelector('[data-intro="site-name"]').click();
      }
    }
  } catch (err) {
    toast.error('Error in user guide! Please start again.');
    introjs.exit(true);
  }
};
