import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import CustomPicker from '../CustomPicker/CustomPicker';
import { getStartEndDate } from '../CustomPicker/utils';
import Loading from '../Loading';
import FleetCharts from './FleetCharts';
import ProductsList from './ProductsList';
import './styles.scss';

const FleetUsage = ({
  vehicleList,
  getFleetUsageDetails,
  fleetUsageDetails,
  loadingFleetUsageSelector,
  loader,
}) => {
  const [selectedOption, setSelectedOption] = useState({
    variable:
      new URL(window.location.href).searchParams.get('ut') || 'Temperature',
    vehicle:
      new URL(window.location.href).searchParams.get('vh') || 'All Vehicles',
  });
  const [selectedRange, setSelectedRange] = useState();
  const [endRange, setEndRange] = useState();
  const [startRange, setStartRange] = useState();
  const [pickerValue, setpickerValue] = useState();
  const usageTypes = ['Temperature', 'Operating Hours', 'SoC', 'Power', 'DoD'];
  const handleChange = e => {
    const { name, value } = e.target;
    setSelectedOption(name, value);
    setSelectedOption({ ...selectedOption, [name]: value });
    const url = new URL(window.location.href);
    url.searchParams.set(e.target.name, e.target.value);
    window.history.pushState(null, null, url);
  };
  // this useEffect is to update the url state
  useEffect(() => {
    const url = new URL(window.location.href);
    // to get the latest th from the url
    url.searchParams.set('th', pickerValue);
    url.searchParams.set(
      'ut',
      new URL(window.location.href).searchParams.get('ut')
    );
    url.searchParams.set(
      'vh',
      new URL(window.location.href).searchParams.get('vh')
    );
    setSelectedRange(null);
    window.history.pushState(null, null, url);
  }, [pickerValue, selectedOption.variable, selectedOption.vehicle]);

  useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.set('ut', 'Temperature');
    url.searchParams.set('vh', 'All Vehicles');
    setSelectedRange(null);
    window.history.pushState(null, null, url);
    handleApply();
  }, [new URL(window.location.href).searchParams.get('ft')]);

  const handleApply = () => {
    setSelectedRange(null);
    const url = new URL(window.location.href);
    const { startDate, endDate } = getStartEndDate(url.searchParams.get('th'));
    getFleetUsageDetails(
      url.searchParams.get('ut'),
      url.searchParams.get('ft'),
      url.searchParams.get('vh'),
      startDate,
      endDate
    );
  };
  if (!vehicleList) return <Loading />;
  return (
    <div className="fleetUsage" data-intro="fleet-satistics-content">
      <div className="row w-100">
        <Form.Group as={Col} data-intro="fleet-satistics-variable">
          <Form.Label>Select variable</Form.Label>
          <Form.Select
            name="ut"
            disabled={loader || loadingFleetUsageSelector}
            defaultValue={selectedOption.variable}
            className="rounded-0"
            aria-label="Select Variable"
            onChange={e => handleChange(e)}>
            {usageTypes.map(usageType => (
              <option key={usageType} value={usageType}>
                {usageType}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} data-intro="fleet-satistics-vehicle">
          <Form.Label>Select vehicle</Form.Label>

          <Form.Select
            name="vh"
            disabled={loader || loadingFleetUsageSelector}
            className="rounded-0"
            aria-label="Select Vehicles"
            defaultValue={selectedOption.vehicle}
            onChange={e => handleChange(e)}>
            <option key="All Vehicles" value="All Vehicles">
              All Vehicles
            </option>
            {vehicleList[0].vst.map(vehicle => (
              <option key={vehicle.vn} value={vehicle.vn}>
                {vehicle.dvn}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} data-intro="fleet-satistics-timeHorizon">
          <Form.Label>Select time horizon</Form.Label>

          <CustomPicker
            disabled={loader || loadingFleetUsageSelector}
            pickerValue={pickerValue || new Date().getFullYear()}
            setpickerValue={setpickerValue}
          />
        </Form.Group>

        <Form.Group as={Col} data-intro="fleet-satistics-apply">
          <Button
            className="mt-4 rounded-0"
            variant="primary"
            disabled={loader || loadingFleetUsageSelector}
            onClick={() => handleApply()}>
            Apply
          </Button>
        </Form.Group>
      </div>

      <div
        style={{
          height: '500px',
        }}>
        {fleetUsageDetails !== null ? (
          !loader ? (
            <FleetCharts
              data-intro="fleet-satistics-chart"
              data={fleetUsageDetails}
              vehicle={selectedOption.vehicle}
              setSelectedRange={setSelectedRange}
              setStartRange={setStartRange}
              selectedRange={selectedRange}
              setEndRange={setEndRange}
            />
          ) : (
            <Loading />
          )
        ) : (
          <Loading />
        )}
      </div>

      <div>
        {fleetUsageDetails !== null &&
        fleetUsageDetails.details.length > 0 &&
        selectedRange ? (
          <ProductsList
            productList={fleetUsageDetails}
            selectedRange={selectedRange}
            pickerValue={pickerValue}
            endRange={endRange.name}
            startRange={startRange}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default FleetUsage;
