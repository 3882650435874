import Axios from 'axios';
import moment from 'moment';

import { setupCache } from 'axios-cache-interceptor';
import apiConfig from './api-config';
import { returnData } from './api-utils';

const instance = Axios.create();
const axios = setupCache(instance, {
  ttl: 45 * 60 * 1000, // 45 min cache set
});

export const clearCache = async ids => {
  await ids?.map(async id => await axios.storage.remove(id));
};

moment.locale('en');

const { baseUrl } = apiConfig;
const isHybrid = process.env.REACT_APP_ESS_API === 'development';
const config = {
  baseURL: baseUrl,
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
    // 'asset-groups': JSON.stringify(sessionStorage.getItem('Asset-Groups')),
  },
};

const catchError = error => {
  if (error.response.status === 403) {
    return (window.location.href = '/access-denied');
  }
  if (error.response.status === 401) {
    sessionStorage.setItem('backupurl', window.location.href);
    return window.location.replace('/RenewToken');
  }
  if (!error.response && !navigator.onLine) {
    return (window.location.pathname = '/networkError');
  } else if (!error.response && navigator.onLine) {
    // return (window.location.pathname = "/serverError");
  } else if (error.response) {
    return Promise.reject(error.response);
  }
  return Promise.reject(error);
};

// Dashboard
const fetchFleetListData = (site, customer, fleet, ok, trip, unknown) =>
  axios
    .get(
      `api/DashboardFleets?siteName=${site}&cusName=${customer}&fleetName=${fleet}&ok=${ok}&trip=${trip}&unknown=${unknown}`,
      config
    )
    .then(returnData)
    .catch(catchError);

// Fleetview Fleets list
const fetchFleetsList = (site, customer, fleet, ok, trip, unknown) =>
  axios
    .get(
      `api/DashboardFleets?siteName=${site}&cusName=${customer}&fleetName=${fleet}&ok=${ok}&trip=${trip}&unknown=${unknown}`,
      config
    )
    .then(returnData)
    .catch(catchError);

// Fetch FleetStatistics data
const fetchFleetstatistics = (siteName, fleetName, year) =>
  axios
    .get(
      `api/FleetStatistics?siteName=${siteName}&fleetName=${fleetName}&year=${year}&includeHistory=true`,
      config
    )
    .then(returnData)
    .catch(catchError);

/// /////////////////////////////////////////////////////////////////////////////////////
// check user
const checkUser = email => {};

/// /////////////////////////////////////////////////////////////////////////////////////////////

// Fleetview Vehicles list
const fetchVehiclesList = (sitename, fleetname) =>
  axios
    .get(
      `/api/FleetHierarchy?siteName=${sitename}&fleetName=${fleetname}&isHybrid=${isHybrid}`,
      config
    )
    .then(returnData)
    .catch(catchError);

// Fleetview Vehicle Product list
const fetchVehicleProductList = (
  sitename,
  fleetname,
  vehiclename,
  ok,
  trip,
  unknown
) =>
  axios
    .get(
      `/api/stable1/vehicleBatteryList?siteName=${sitename}&fleetName=${fleetname}&vehicleName=${vehiclename}`,
      config
    )
    .then(returnData)
    .catch(catchError);

// Fleetview Module list
const fetchModuleList = (
  sitename,
  fleetname,
  vehiclename,
  modulename,
  ok,
  trip,
  unknown
) =>
  axios
    .get(
      `/api/stable1/batteryModuleList?siteName=${sitename}&fleetName=${fleetname}&vehicleName=${vehiclename}&pecUniqueSerialNumber=${modulename}`,
      config
    )
    .then(returnData)
    .catch(catchError);

const fetchMapDataList = () => {
  return {};
};

// DashboardFIlterList
const fetchFilterListData = () =>
  axios
    .get(`api/DashboardFilters`, config)

    .then(returnData)
    .catch(catchError);

// FETCH_MONITORED_COUNT
const fetchMonitoredCountData = () =>
  axios
    .get(`/api/DashboardBreadCrumb`, config)
    .then(returnData)
    .catch(catchError);

// ESS histogram
const fetchHistogramData = () => {};

const fetchBoxPlotData = (fleetName, startDate, endDate, docType) => {
  return {};
};

const fetchOverviewData = (site, fleet) => {};

const fetchOverallConditionData = (site, customer, fleet, ok, trip, unknown) =>
  axios
    .get(
      `api/DashboardFleets?siteName=${site}&cusName=${customer}&fleetName=${fleet}&ok=${ok}&trip=${trip}&unknown=${unknown}`,
      config
    )
    .then(returnData)
    .catch(catchError);

/// ////////////////////////////////////////////////////
const fetchCellVoltageData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate
) => {};

// sych chart

const fetchSyncGraphData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate
) => {};

const fetchBatteryStateOfChargeData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate
) => {};

const fetchBatterySOCRangeData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate,
  datetype
) =>
  axios
    .get(
      `/api/BatterySOCRange?vehicleName=${vehicleName}&pecUniqueSerialNumber=${pecUniqueSerialNumber}&startDate=${startDate}&endDate=${endDate}&datetype=${datetype}`,
      config
    )
    .then(returnData)
    .catch(catchError);

const fetchBatteryOverallConditionData = (
  sitename,
  fleetname,
  vehicleName,
  pecUniqueSerialNumber
) =>
  axios
    .get(
      `/api/BatteryOverallCondition?siteName=${sitename}&fleetName=${fleetname}&vehicleName=${vehicleName}&pecUniqueSerialNumber=${pecUniqueSerialNumber}&packUniqueSerialNumber=${sessionStorage.getItem(
        'selectedPack'
      )}&isHybrid=${isHybrid}`,
      config
    )
    .then(returnData)
    .catch(catchError);

const fetchBatteryOverviewData = (
  vehicleName,
  pecUniqueSerialNumber,
  datetype
) =>
  axios
    .get(
      `/api/BatteryOverView?vehicleName=${vehicleName}&pecUniqueSerialNumber=${pecUniqueSerialNumber}&datetype=${datetype}&packUniqueSerialNumber=${sessionStorage.getItem(
        'selectedPack'
      )}`,
      config
    )
    .then(returnData)
    .catch(catchError);

/// ////////////////////////////////////////////////////////////////////////////////////////////////////////

const fetchTemperatureHistogramData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate,
  datetype
) =>
  axios
    .get(
      `/api/BatteryUsageTemperature?vehicleName=${vehicleName}&pecUniqueSerialNumber=${pecUniqueSerialNumber}&startDate=${startDate}&endDate=${endDate}&datetype=${datetype}&packUniqueSerialNumber=${sessionStorage.getItem(
        'selectedPack'
      )}&isHybrid=${isHybrid}`,
      { ...config, cache: false, id: 'BatteryUsageTemperature' }
    )
    .then(returnData)
    .catch(catchError);

/// ////////////////////////////////////////////////
const fetchUsgSocHistogramData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate,
  datetype
) =>
  axios
    .get(
      `/api/BatterySOC?vehicleName=${vehicleName}&pecUniqueSerialNumber=${pecUniqueSerialNumber}&startDate=${startDate}&endDate=${endDate}&datetype=${datetype}&packUniqueSerialNumber=${sessionStorage.getItem(
        'selectedPack'
      )}&isHybrid=${isHybrid}`,
      { ...config, cache: false, id: 'BatterySOC' }
    )
    .then(returnData)
    .catch(catchError);

const fetchUsgPowHistogramData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate,
  datetype
) =>
  axios
    .get(
      `/api/BatteryUsagePower?vehicleName=${vehicleName}&pecUniqueSerialNumber=${pecUniqueSerialNumber}&startDate=${startDate}&endDate=${endDate}&datetype=${datetype}&packUniqueSerialNumber=${sessionStorage.getItem(
        'selectedPack'
      )}&isHybrid=${isHybrid}`,
      { ...config, cache: false, id: 'BatteryUsagePower' }
    )
    .then(returnData)
    .catch(catchError);

const fetchUsgDodHistogramData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate,
  datetype
) =>
  axios
    .get(
      `/api/BatteryDod?vehicleName=${vehicleName}&pecUniqueSerialNumber=${pecUniqueSerialNumber}&startDate=${startDate}&endDate=${endDate}&datetype=${datetype}&packUniqueSerialNumber=${sessionStorage.getItem(
        'selectedPack'
      )}&isHybrid=${isHybrid}`,
      { ...config, cache: false, id: 'BatteryDod' }
    )
    .then(returnData)
    .catch(catchError);

/// ///////////////////////////////////////////////////////////////////////

const fetchUsgDodHeatmapData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate,
  datetype
) =>
  axios
    .get(
      `/api/BatteryDodHistogram?vehicleName=${vehicleName}&pecUniqueSerialNumber=${pecUniqueSerialNumber}&startDate=${startDate}&endDate=${endDate}&datetype=${datetype}&packUniqueSerialNumber=${sessionStorage.getItem(
        'selectedPack'
      )}&isHybrid=${isHybrid}`,
      config
    )
    .then(returnData)
    .catch(catchError);
const fetchUsgPowHeatmapData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate,
  datetype
) =>
  axios
    .get(
      `/api/BatteryUsagePowerHistogram?vehicleName=${vehicleName}&pecUniqueSerialNumber=${pecUniqueSerialNumber}&startDate=${startDate}&endDate=${endDate}&datetype=${datetype}&packUniqueSerialNumber=${sessionStorage.getItem(
        'selectedPack'
      )}&isHybrid=${isHybrid}`,
      config
    )
    .then(returnData)
    .catch(catchError);

const fetchUsgSocHeatmapData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate,
  datetype
) =>
  axios
    .get(
      `/api/BatterySOCHistogram?vehicleName=${vehicleName}&pecUniqueSerialNumber=${pecUniqueSerialNumber}&startDate=${startDate}&endDate=${endDate}&datetype=${datetype}&packUniqueSerialNumber=${sessionStorage.getItem(
        'selectedPack'
      )}&isHybrid=${isHybrid}`,
      config
    )
    .then(returnData)
    .catch(catchError);

const fetchUsgTempHeatmapData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate,
  datetype
) =>
  axios
    .get(
      `/api/BatteryUsageTemperatureHistogram?vehicleName=${vehicleName}&pecUniqueSerialNumber=${pecUniqueSerialNumber}&startDate=${startDate}&endDate=${endDate}&datetype=${datetype}&packUniqueSerialNumber=${sessionStorage.getItem(
        'selectedPack'
      )}&isHybrid=${isHybrid}`,
      config
    )
    .then(returnData)
    .catch(catchError);

/// ///////////////////////////////////////////////////////////////////////

const fetchOperatingHoursHistogramData = (
  vehicleName,
  pecUniqueSerialNumber,
  startDate,
  endDate,
  datetype
) =>
  axios
    .get(
      `/api/BatteryOperatingHours?vehicleName=${vehicleName}&pecUniqueSerialNumber=${pecUniqueSerialNumber}&startDate=${startDate}&endDate=${endDate}&datetype=${datetype}&packUniqueSerialNumber=${sessionStorage.getItem(
        'selectedPack'
      )}&isHybrid=${isHybrid}`,
      config
    )
    .then(returnData)
    .catch(catchError);

const fetchDetailViewTimetrendData = (
  selectedVehicle,
  startDate,
  endDate,
  docType,
  pecUniqueSerialNumber,
  datetype
) => {
  return axios
    .get(
      `/api/BatteryTimeTrends?vehicleName=${selectedVehicle}&pecUniqueSerialNumber=${pecUniqueSerialNumber}&signalName=${docType}&startDate=${startDate}&endDate=${endDate}&datetype=${datetype}&packUniqueSerialNumber=${sessionStorage.getItem(
        'selectedPack'
      )}&isHybrid=${isHybrid}`,
      config
    )
    .then(returnData)
    .catch(catchError);
};

const checkLogin = (username, password) => {};

const changePassword = (userName, oldpassword, newpassword) => {};

const saveSite = (editSite, siteFormValues) => {
  const isUpdate = editSite;

  const data = {
    siteName: isUpdate
      ? siteFormValues.siteName
      : siteFormValues.displaySiteName,
    siteId: siteFormValues.siteID,
    operatorName: siteFormValues.siteOpname,
    operatorID: siteFormValues.siteOpID,
    nameOfContact: siteFormValues.siteContactP,
    jobFunctionOfContact: siteFormValues.jobFunContct,
    emailAddress: siteFormValues.siteEmail,
    phoneNumber: siteFormValues.sitePhone,
    siteLocation: siteFormValues.sitelocN,
    siteMapAddress: siteFormValues.siteAddress,
    displaySiteName: siteFormValues.displaySiteName,
  };
  return axios
    .post(`/api/UpsertSiteConfiguration?isUpdate=${isUpdate}`, data, {
      ...config,
      cache: false,
    })
    .then(returnData)
    .catch(catchError);
  // }
};

const saveFleet = fleetobj => {
  const isUpdate = fleetobj.isUpdate;
  const data = {
    fleetName: fleetobj.fleetName || fleetobj.displayFleetName,
    fleetId: fleetobj.fleetId,
    connectivityType: fleetobj.fleetConType,
    connectivityKeyPath: fleetobj.connectivityKeyPath,
    fleetDataPath: fleetobj.fleetDPath,
    siteId: sessionStorage.getItem('siteId'),
    displayFleetName: fleetobj.displayFleetName,
    platform: fleetobj.platform,
    designedThroughput: fleetobj.designedThroughput,
    maxThroughput: fleetobj.maxThroughput,
    firstLineStartColor: fleetobj.firstLineStartColor,
    firstLineEndColor: fleetobj.firstLineEndColor,
    secondLineStartColor: fleetobj.secondLineStartColor,
    secondLineEndColor: fleetobj.secondLineEndColor,
    webPortalLive: fleetobj.webPortalLive,
    dataApiLive: fleetobj.dataApiLive,
    isDraft: fleetobj.isDraft,
    slaStartDate: fleetobj.slaStartDate,
    externalEntitlements: fleetobj.accessExpirationDetailList,
  };

  return axios
    .post(`/api/UpsertFleetConfiguration?isUpdate=${isUpdate}`, data, {
      ...config,
      cache: false,
    })
    .then(returnData)
    .catch(catchError);
};

const getSetupSiteList = siteName =>
  axios
    .get(`/api/SiteInformationData?siteName=${siteName}`, {
      ...config,
      cache: false,
    })
    .then(returnData)
    .catch(catchError);

const getSetupFleetList = fleetName =>
  axios
    .get(`/api/FleetInformationData?fleetName=${fleetName}`, {
      ...config,
      cache: false,
    })
    .then(returnData)
    .catch(catchError);

const getEventList = (
  fleetName,
  vehicleName,
  pecUniqueSerialNumber,
  filterStartDate,
  filterEndDate,
  dateType,
  eventType,
  selectedVehicle,
  selectedProduct,
  searchColumn,
  searchColumnValue
) => {
  let pecUniqueSerialNumberFormat = pecUniqueSerialNumber;
  if (sessionStorage.getItem('selectedFleet') === 'ABB ESS Demo Fleet') {
    pecUniqueSerialNumberFormat =
      pecUniqueSerialNumber +
      '_' +
      sessionStorage.getItem('selectedProductDname');
  }
  return axios
    .get(
      `/api/BatteryLevelEvents?fleetName=${fleetName}&vehicleName=${vehicleName}&pecUniqueSerialNumber=${pecUniqueSerialNumberFormat}&ipAddress=${sessionStorage.getItem(
        'selectedProductIP'
      )}&esdt=${filterStartDate}&eedt=${filterEndDate}&datt=${dateType}&vehicleNameFilter=${selectedVehicle}&essn=${selectedProduct}&packUniqueSerialNumber=${sessionStorage.getItem(
        'selectedPack'
      )}`,
      config
    )
    .then(returnData)
    .catch(catchError);
};

const fetchBatteryRelData = (vehicleName, pecUniqueSerialNumber) =>
  axios
    .get(
      `/api/BatteryReliability?vehicleName=${vehicleName}&pecUniqueSerialNumber=${pecUniqueSerialNumber}&isUnmount=${sessionStorage.getItem(
        'unmountedESSclicked'
      )}&packUniqueSerialNumber=${sessionStorage.getItem('selectedPack')}&isHybrid=${isHybrid} `,
      config
    )
    .then(returnData)
    .catch(catchError);
const fetchBatteryStrData = (vehicleName, pecUniqueSerialNumber) =>
  axios
    .get(
      `/api/BatteryStress?vehicleName=${vehicleName}&pecUniqueSerialNumber=${pecUniqueSerialNumber}&isUnmount=${sessionStorage.getItem(
        'unmountedESSclicked'
      )}&packUniqueSerialNumber=${sessionStorage.getItem('selectedPack')}&isHybrid=${isHybrid}`,
      config
    )
    .then(returnData)
    .catch(catchError);

const fetchVehicleAvailData = (sitename, fleetname, vehicleName) =>
  axios
    .get(
      `/api/VehicleAvailability?siteName=${sitename}&fleetName=${fleetname}&vehicleName=${vehicleName}&pecUniqueSerialNumber=${
        sessionStorage.getItem('selectedVehicleProduct') === 'null'
          ? ''
          : sessionStorage.getItem('selectedVehicleProduct')
      }&isHybrid=${isHybrid}`,
      config
    )
    .then(returnData)
    .catch(catchError);

const fetchVehicleRelData = (sitename, fleetname, vehicleName) =>
  axios
    .get(
      `/api/VehicleReliability?siteName=${sitename}&fleetName=${fleetname}&vehicleName=${vehicleName}&pecUniqueSerialNumber=${
        sessionStorage.getItem('selectedVehicleProduct') === 'null'
          ? ''
          : sessionStorage.getItem('selectedVehicleProduct')
      }&isHybrid=${isHybrid}`,
      config
    )
    .then(returnData)
    .catch(catchError);

const fetchVehicleStrData = (sitename, fleetname, vehicleName) =>
  axios
    .get(
      `/api/VehicleStress?siteName=${sitename}&fleetName=${fleetname}&vehicleName=${vehicleName}&pecUniqueSerialNumber=${
        sessionStorage.getItem('selectedVehicleProduct') === 'null'
          ? ''
          : sessionStorage.getItem('selectedVehicleProduct')
      }&isHybrid=${isHybrid}`,
      config
    )
    .then(returnData)
    .catch(catchError);

const saveEvent = (siteName, fleetName, vehicle, eventFormData) => {
  const data = {
    SiteName: siteName,
    FleetName: fleetName,
    VehicleName: vehicle,
    IPAddress: eventFormData.pecIP,
    ExchangeType: eventFormData.exchangetype.trim(),
    OldESSArticleNumber: eventFormData.oessan,
    NewESSArticleNumber: eventFormData.nessan.trim(),
    OldESSSerialNumber: eventFormData.oesssn,
    NewESSSerialNumber: eventFormData.nesssn.trim(),
    OldPECArticleNumber: eventFormData.opecan,
    NewPECArticleNumber: eventFormData.npecan.trim(),
    OldPECSerialNumber: eventFormData.opecsn,
    NewPECSerialNumber: eventFormData.npecsn.trim(),
    Comments: eventFormData.comments.trim(),
    PreviousArticleNumber: eventFormData.prepecan.trim(),
    PreviousSerialNumber: eventFormData.prepecsn.trim(),
    ProductType: eventFormData.producttype,
  };

  return axios
    .put('/api/UpdateBatteryConfigFile', data, config)
    .then(returnData)
    .catch(catchError);
};

// ***** undo event

const undoEvent = (siteName, fleetName, vehicle, obj) => {
  const data = {
    siteName,
    fleetName,
    vehicleName: vehicle,
    ipAddress: obj.ip,
    pecUniqueSerialNumber: obj.pun,
    NewPECUniqueSerialNumber: obj.npun,
    Code: obj.cd,
    Message: obj.ms,
    ESSArticleNumber: obj.ean,
    ESSSerialNumber: obj.esn,
  };

  return axios
    .put('/api/RevertBatteryConfigFile', data, config)
    .then(returnData)
    .catch(catchError);
};

const getVEventList = (
  site,
  fleetName,
  vehicleName,
  filterStartDate,
  filterEndDate,
  dateType,
  eventType,
  selectedVehicle,
  selectedProduct,
  searchColumn,
  searchColumnValue
) => {
  let selected_Product = selectedProduct;
  if (selectedProduct === 'All product') {
    selected_Product = 'All';
  }
  return axios
    .get(
      `/api/VehicleLevelEvents?siteName=${site}&fleetName=${fleetName}&vehicleName=${vehicleName}&esdt=${filterStartDate}&eedt=${filterEndDate}&datt=${dateType}&vehicleNameFilter=${selectedVehicle}&essn=${selected_Product}`,
      config
    )
    .then(returnData)
    .catch(catchError);
};

const getAllEventList = (
  fleetName,
  vehicleName,
  pecUniqueSerialNumber,
  packSerialNumber,
  startDateTime,
  endDateTime,
  dateType,
  filterColumnKey,
  filterColumnKeyValue,
  pageSize,
  pageNumber,
  sortType,
  sortTo
) => {
  return axios
    .get(
      `/api/Events?fleetName=${fleetName}&vehicleName=${vehicleName}&pecUniqueSerialNumber=${pecUniqueSerialNumber}&packSerialNumber=${packSerialNumber}&startDateTime=${startDateTime}&endDateTime=${endDateTime}&dateType=${dateType}&filterColumnKey=${filterColumnKey}&filterColumnKeyValue=${filterColumnKeyValue}&pageSize=${pageSize || 20}&pageNumber=${pageNumber || 0}&sortType=${sortType}&sortTo=${sortTo}`,
      config
    )
    .then(returnData)
    .catch(catchError);
};

// Unmounted ESS list
const fetchUnmountedESSList = (sitename, fleetname) =>
  axios
    .get(
      `/api/UnmountedEss?siteName=${sitename}&fleetName=${fleetname}`,
      config
    )
    .then(returnData)
    .catch(catchError);

const saveproductSetup = obj => {
  const data = {
    fleetName:
      obj.fleetName === 'New fleet' ? obj.displayFleetName : obj.fleetName,
    fleetId: sessionStorage.getItem('fleetId'),
    lifeTimeInYears: obj.dSpan,
    capacityAfterDesignedLifetime: obj.capacityAfterDesignedLifetime,
    throughputLifetime: obj.throughputLifetime,
    designSettings: [...obj.designBudget],
  };

  return axios
    .post('/api/UpsertFleetThreshold', data, config)
    .then(returnData)
    .catch(catchError);
};

const saveProductInfoSetup = obj => {
  const data = {
    fleetName:
      obj.fleetName === 'New fleet' ? obj.displayFleetName : obj.fleetName,
    fleetId: sessionStorage.getItem('fleetId'),
    productType: obj.productType,
    data: [
      {
        batteryPrefix: obj.batteryPrefix,
        essArticleNumber: obj.essArticleNumber,
        packPrefix: obj.packPrefix,
        ratedCapacity: obj.ratedCapacity,
        ratedVoltage: obj.ratedVoltage,
        ratedEnergy: obj.ratedEnergy,
        maxCurrent: obj.maxCurrent,
        reliabilityThresholdNotRelibleState: obj.relThresNotRelStat,
        utilizationThresholdAboveNormalUtilizationState:
          obj.utilThresAbNUtilStat,
        minThresholdTempearture: obj.tempThresStrt,
        maxThresholdTempearture: obj.tempThresEnd,
        minThresholdSOC: obj.socThresStrt,
        maxThresholdSOC: obj.socThresEnd,
        maxThresholdCycles: obj.cylThres,
        maxThresholdCRate: obj.crateThres,
        numberOfBatterySystem: obj.numberOfBatterySystem,
        numberOfStrings: obj.numberOfStrings,
        converterPrefix: obj.converterPrefix,
        converterEnable: obj.converterEnable,
        sohModelOutput: obj.sohModelOutput,
      },
    ],
  };
  return axios
    .post('/api/UpsertProductInformation', data, config)
    .then(returnData)
    .catch(catchError);
};

const getPrdtSetupValues = fleetName =>
  axios
    .get(`/api/FleetThresholdBins?fleetName=${fleetName}`, {
      ...config,
      cache: false,
    })
    .then(returnData)
    .catch(catchError);

const getPrdtInfoSetupValues = fleetName =>
  axios
    .get(`/api/ProductInformation?fleetName=${fleetName}`, {
      ...config,
      cache: false,
    })
    .then(returnData)
    .catch(catchError);

const fetchEmailalertSettingsData = fleetName =>
  axios
    .get(
      `/api/EmailAlert?fleetName=${fleetName}&emailId=${
        JSON.parse(sessionStorage.getItem('profile')).email
      }`,
      { ...config, cache: false }
    )
    .then(returnData)
    .catch(catchError);

const saveEmailalertSettings = (
  essName,
  additionalInfo,
  alertType,
  alertFrequency,
  alertValidity,
  status,
  un,
  actions
) => {
  const data = {
    essName,
    additionalInformation: additionalInfo,
    alertType,
    alertFrequency,
    validTime: alertValidity,
    status,
    fleetName: sessionStorage.getItem('selectedFleet'),
    uniqueNumber: un,
    emailId: JSON.parse(sessionStorage.getItem('profile')).email,
    action: actions,
  };
  return axios
    .post('/api/UpsertEmailAlert', data, config)
    .then(returnData)
    .catch(catchError);
};

const saveVehicleSetup = obj => {
  const data = {
    fleetName:
      obj.fleetName === 'New fleet' ? obj.displayFleetName : obj.fleetName,
    fleetId: sessionStorage.getItem('fleetId'),
    vehicleType: obj.vehType,
    vehiclePrefix: obj.vehiclePrefix,
    data: [
      {
        relibilityThreshold: obj.relThres,
        utilizationThreshold: obj.utiThres,
      },
    ],
  };
  return axios
    .post('/api/UpsertVehicleInformation', data, config)
    .then(returnData)
    .catch(catchError);
};

const getVehicleSetupValues = fleetName =>
  axios
    .get(`/api/VehicleInformation?fleetName=${fleetName}`, {
      ...config,
      cache: false,
    })
    .then(returnData)
    .catch(catchError);

const getSetupSidemenuList = () =>
  axios
    .get(`api/SiteConfigurationMenuData`, { ...config, cache: false })
    .then(returnData)
    .catch(catchError);

const getUsedFleetNameList = () =>
  axios
    .get(`/api/FleetConfigurationMetaData`, { ...config, cache: false })
    .then(returnData)
    .catch(catchError);

const fetchSOHData = (fleetName, bPUniqueSerialNumber, scuID) => {
  return axios
    .get(
      `/api/BatteryStateOfHealth?fleetName=${fleetName}&bPUniqueSerialNumber=${bPUniqueSerialNumber}&scuID=${scuID}`,
      config
    )
    .then(returnData)
    .catch(catchError);
};

const getValidateFleetDataPath = (fleetDataPath, connectivityType) =>
  axios
    .get(
      `/api/ValidateFleetPath?fleetPath=${fleetDataPath}&connectivityType=${connectivityType}`,
      { ...config, cache: false }
    )
    .then(returnData)
    .catch(catchError);

const getFleetDataforSetup = fleetData =>
  axios
    .get(`/api/PlatformLookup`, { ...config, cache: false })
    .then(returnData)
    .catch(catchError);

// Fetch Diagnostic data
const fetchDiagnosticList = (
  fleetName,
  vehicleName,
  ipaddress,
  pecUniqueSerialNumber,
  startDate,
  endDate,
  dateType,
  filterKeysName,
  filterKeysValue,
  packSerialNumber,
  pageSize,
  pageNumber,
  sortType,
  sortTo
) => {
  // return diagnosticData;
  return axios
    .get(
      `/api/DiagnosticEvents?fleetName=${fleetName}&vehicleName=${vehicleName}&ipaddress=${ipaddress}&pecUniqueSerialNumber=${pecUniqueSerialNumber}&startDate=${startDate}&endDate=${endDate}&dateType=${dateType}&filterKeyName=${filterKeysName}&filterKeysValue=${filterKeysValue}&packSerialNumber=${packSerialNumber}&pageSize=${pageSize}&pageNumber=${pageNumber}&sortType=${sortType}&sortTo=${sortTo}`,
      config
    )
    .then(returnData)
    .catch(catchError);
};

const fetchVehicleDetails = (sitename, fleetname, vehicleName) =>
  axios
    .get(
      `/api/VehicleDetails?siteName=${sitename}&fleetName=${fleetname}&vehicleName=${vehicleName}`,
      config
    )
    .then(returnData)
    .catch(catchError);

const fetchEmergencyStatus = () =>
  axios
    .get(`/api/IsSafeModeEnabled`, { ...config, cache: false })
    .then(returnData)
    .catch(catchError);

const setEmergencyStatus = ({ message, secret, enabled }) =>
  axios
    .post(`/api/ToggleSafeMode`, { message, secret, enabled }, config)
    .then(returnData)
    .catch(catchError);
const setEmergencyKey = ({ currentSecret, newSecret }) =>
  axios
    .post(`/api/UpdateSafeModeSecret`, { currentSecret, newSecret }, config)
    .then(returnData)
    .catch(catchError);

const createMessage = data =>
  axios
    .post(`/api/UpsertBroadcastMessage`, data, config)
    .then(returnData)
    .catch(catchError);

const fetchAllMessages = () =>
  axios
    .get(`/api/BroadcastMessages`, { ...config, cache: false })
    .then(returnData)
    .catch(catchError);
const fetchActiveMessages = () =>
  axios
    .get(`/api/ActiveBroadcastMessages`, { ...config, cache: false })
    .then(returnData)
    .catch(catchError);

const deleteMessage = ({ id }) =>
  axios
    .post(`/api/DeleteBroadcastMessage?messageId=${id}`, {}, config)
    .then(returnData)
    .catch(catchError);

const fetchExcelReportData = (
  fleetname,
  startDate,
  endDate,
  fleetReportOptions
) => {
  if (fleetReportOptions.type === 'emailfleetword') {
    return axios
      .post(`/api/SendFleetReport`, fleetReportOptions.uploadFile, {
        baseURL: baseUrl,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        },
      })
      .then(returnData)
      .catch(catchError);
  } else if (fleetReportOptions.type === 'download') {
    axios
      .get(
        `/api/DownloadFleetReport?fileName=${fleetname}&fleetName=${fleetname}&startDate=${startDate}&endDate=${endDate}&mostStressed=${fleetReportOptions.m_stress}&leastReliable=${fleetReportOptions.l_reliable}&mostEnergyThroughput=${fleetReportOptions.m_enrgy}&leastEnergyThroughput=${fleetReportOptions.l_enrgy}&isHybrid=${isHybrid}`,
        {
          baseURL: baseUrl,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
          },
          responseType: 'blob',
        }
      )
      .then(response => {
        const type = response.headers['application/octet-stream'];
        const blob = new Blob([response.data], {
          type,
          encoding: 'UTF-8',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', fleetReportOptions.reportName + '.xlsx');
        link.click();
        link.remove();
      });
  } else if (fleetReportOptions.type === 'email') {
    return axios
      .get(
        `/api/SendFleetReportEmail?fileName=${
          fleetReportOptions.reportName + '.xlsx'
        }&fleetName=${fleetname}&startDate=${startDate}&endDate=${endDate}&mostStressed=${
          fleetReportOptions.m_stress
        }&leastReliable=${fleetReportOptions.l_reliable}&mostEnergyThroughput=${
          fleetReportOptions.m_enrgy
        }&leastEnergyThroughput=${fleetReportOptions.l_enrgy}&isHybrid=${isHybrid}`,
        config
      )
      .then(returnData)
      .catch(catchError);
  } else if (fleetReportOptions.type === 'emailword') {
    return axios
      .post(`/api/UploadEssReport`, fleetReportOptions.uploadFile, {
        baseURL: baseUrl,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        },
      })
      .then(returnData)
      .catch(catchError);
  } else {
    return axios
      .get(
        `/api/GenerateExcelReport?fleetName=${fleetname}&startDate=${startDate}&endDate=${endDate}&mostStressed=${fleetReportOptions.m_stress}&leastReliable=${fleetReportOptions.l_reliable}&mostEnergyThroughput=${fleetReportOptions.m_enrgy}&leastEnergyThroughput=${fleetReportOptions.l_enrgy}&isHybrid=${isHybrid}`,
        config
      )
      .then(returnData)
      .catch(catchError);
  }
};

const fetchFleetWordReportData = (fleetname, slaDate, fleetReportOptions) => {
  // if (fleetReportOptions.type === 'generate') {
  return axios
    .get(
      `/api/FleetOverView?fleetName=${fleetname}&reportingPeriod=${slaDate}`,
      config
    )
    .then(returnData)
    .catch(catchError);
  // }
};

const fetchFleetSOCReportData = (fleetname, slaDate, fleetReportOptions) => {
  return axios
    .get(
      `/api/FleetWordUsage?usageType=SOC&fleetName=${fleetname}&reportingPeriod=${slaDate}&isHybrid=false`,
      config
    )
    .then(returnData)
    .catch(catchError);
};

const fetchFleetTemperatureReportData = (
  fleetname,
  slaDate,
  fleetReportOptions
) => {
  return axios
    .get(
      `/api/FleetWordUsage?usageType=Temperature&fleetName=${fleetname}&reportingPeriod=${slaDate}&isHybrid=false`,
      config
    )
    .then(returnData)
    .catch(catchError);
};

const fetchFleetConfigReportData = (fleetname, slaDate, fleetReportOptions) => {
  return axios
    .get(
      `/api/FleetConfigChanges?fleetName=${fleetname}&reportingPeriod=${slaDate}`,
      config
    )
    .then(returnData)
    .catch(catchError);
};

const getAssetGroup = access_token =>
  axios
    .get(`/api/UserAssetGroups`, {
      baseURL: baseUrl,
      headers: {
        Authorization: `Bearer ` + access_token,
      },
      cache: false,
    })
    .then(response => {
      const groupData = response.data.payload?.entries;
      sessionStorage.setItem('assetGroups', JSON.stringify(groupData));
      return groupData;
    })
    .catch(error => console.log(error));

const fetchFleetUsageDetails = (
  usageType,
  fleetName,
  vehicleName,
  startDate,
  endDate
) => {
  usageType = usageType.split(' ').join('');
  return axios
    .get(
      `/api/FleetUsage?usageType=${usageType}&fleetName=${fleetName}&vehicleName=${vehicleName}&startDate=${startDate}&endDate=${endDate}`,
      { ...config }
    )
    .then(returnData)
    .catch(catchError);
};

const api = {
  fetchFleetListData,
  fetchMapDataList,
  fetchFilterListData,
  fetchDetailViewTimetrendData,
  checkLogin,
  changePassword,
  fetchHistogramData,
  fetchBoxPlotData,
  fetchOverviewData,
  fetchOverallConditionData,
  fetchFleetsList,
  fetchVehiclesList,
  fetchMonitoredCountData,
  fetchVehicleProductList,
  fetchModuleList,
  fetchCellVoltageData,
  fetchBatteryOverallConditionData,
  fetchBatteryStateOfChargeData,
  fetchBatterySOCRangeData,
  fetchBatteryOverviewData,
  fetchTemperatureHistogramData,
  fetchUsgSocHistogramData,
  fetchUsgPowHistogramData,
  fetchUsgDodHistogramData,
  fetchUsgDodHeatmapData,
  fetchUsgPowHeatmapData,
  fetchUsgSocHeatmapData,
  fetchUsgTempHeatmapData,
  fetchOperatingHoursHistogramData,
  checkUser,
  fetchSyncGraphData,
  saveSite,
  saveFleet,
  getSetupSiteList,
  getSetupFleetList,
  getEventList,
  fetchBatteryRelData,
  fetchBatteryStrData,
  fetchVehicleAvailData,
  fetchVehicleRelData,
  fetchVehicleStrData,
  saveEvent,
  getVEventList,
  getAllEventList,
  undoEvent,
  fetchUnmountedESSList,
  saveproductSetup,
  getPrdtSetupValues,
  fetchEmailalertSettingsData,
  saveEmailalertSettings,
  saveProductInfoSetup,
  getPrdtInfoSetupValues,
  saveVehicleSetup,
  getVehicleSetupValues,
  getSetupSidemenuList,
  getUsedFleetNameList,
  fetchSOHData,
  getValidateFleetDataPath,
  fetchFleetstatistics,
  getFleetDataforSetup,
  fetchDiagnosticList,
  fetchVehicleDetails,
  fetchEmergencyStatus,
  setEmergencyStatus,
  setEmergencyKey,
  createMessage,
  fetchAllMessages,
  deleteMessage,
  fetchActiveMessages,
  fetchExcelReportData,
  getAssetGroup,
  fetchFleetUsageDetails,
  fetchFleetWordReportData,
  fetchFleetConfigReportData,
  fetchFleetSOCReportData,
  fetchFleetTemperatureReportData,
};

export default api;
