import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { RightDirectionArrow } from '../../assets/images';
import { platformVersion } from '../../utils/excel-utils';
import { getStartEndDate } from '../CustomPicker/utils';
import history from './../../history';
import { SortIcon } from './../Icons';

const ProductsList = ({
  productList,
  selectedRange,
  pickerValue,
  endRange,
  startRange,
}) => {
  const data = productList?.details.filter(
    detail => detail.range.replace(/[^\d-]/g, '') === selectedRange
  )[0]?.data;
  const startRangeValue = startRange.replace(
    /^(\d+)(\.\d{0,2})?.*$/,
    (match, integerPart, decimalPart) => {
      if (!decimalPart) return `${integerPart}`;
      if (decimalPart.length === 2) return `${integerPart}${decimalPart}0`;
      return `${integerPart}${decimalPart.slice(0, 3)}`;
    }
  );
  const [asc, setAsc] = useState(false);
  const [sortedData, setSortedData] = useState(
    data?.sort((a, b) => b.hours - a.hours)
  );
  const handleSort = () => {
    setSortedData([...sortedData].reverse());
    setAsc(!asc);
  };
  useEffect(() => {
    setSortedData(data?.sort((a, b) => b.hours - a.hours));
    setAsc(true);
  }, [data]);
  const hasStringName = data?.some(detail => detail.stringName !== null);
  const makeRedirectLink = (rangeDetail, variable) => {
    if (variable.includes('Temperature')) {
      variable = 'usgtemp';
    }
    if (variable.includes('SoC')) {
      variable = 'usgsoc';
    }
    if (variable.includes('Power')) {
      variable = 'usgpow';
    }
    if (variable.includes('Depth')) {
      variable = 'usgdod';
    }
    if (variable.includes('Time')) {
      variable = 'usgoh';
    }
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const siteName = searchParams.get('st');
    const fleetName = searchParams.get('ft');
    const timeHorizom = searchParams.get('th');
    let { startDate, endDate } = getStartEndDate(timeHorizom);
    startDate = Moment(startDate)
      .subtract(1, 'days')
      .format('YYYY-MM-DDTHH:mm:ss');
    endDate = Moment(endDate).format('YYYY-MM-DDTHH:mm:ss');
    let newUrl = '';
    if (platformVersion() < '3') {
      newUrl = `/FleetView/VehicleView/VehicleProductsView?st=${siteName}&ft=${fleetName}&vc=${rangeDetail.vehicleName}&pdn=${rangeDetail.essName}&bpu=${rangeDetail.bpu}&tb=Usage&utb=${variable}&sdt=${startDate}&edt=${endDate}&pip=${rangeDetail.ipAddress}&vp=${rangeDetail.pec}&pickerValue=${pickerValue}&cdty=Custom`;
    } else {
      newUrl = `/FleetView/VehicleView/VehicleProductsView/PackView?st=${siteName}&ft=${fleetName}&fty=BESS&vc=${rangeDetail.vehicleName}&vp=${rangeDetail.pec}&pk=${rangeDetail.scu}&pkdn=${rangeDetail.stringName}&pip=${rangeDetail.ipAddress}&pdn=${rangeDetail.essName}&sdt=${startDate}&edt=${endDate}&tb=Usage&bpu=${rangeDetail.bpu}&scuID=${rangeDetail.scuid}&utb=${variable}&pickerValue=${pickerValue}&cdty=Custom`;
    }

    return newUrl.toString();
  };

  return (
    <div data-intro="fleet-satistics-list" style={{ minHeight: '300px' }}>
      <Row>
        <Col>
          <h5>
            {productList.xTitle}_Bin ({startRangeValue}&nbsp;-&nbsp;{endRange})
          </h5>
        </Col>
      </Row>
      <div style={{ width: '100%' }}>
        <table className="table table-hover table-borderless">
          <thead
            className="border-bottom border-black"
            style={{ fontSize: '14px' }}>
            <tr>
              <th scope="col">Battery</th>
              {hasStringName ? <th>STRING</th> : ''}
              <th scope="col">Vehicle</th>
              <th
                scope="col"
                style={{ cursor: 'pointer' }}
                onClick={() => handleSort()}>
                Hours <SortIcon asc={asc} />
              </th>
            </tr>
          </thead>
          <tbody>
            {productList.details &&
              sortedData?.map((rangeDetail, idx) => (
                <tr
                  key={idx}
                  onClick={() => {
                    history.push(
                      makeRedirectLink(rangeDetail, productList.xTitle)
                    );
                  }}
                  style={{
                    border: 'none',
                    cursor: 'pointer',
                  }}>
                  <td>{rangeDetail.essName}</td>
                  {hasStringName ? <td>{rangeDetail.stringName}</td> : ''}
                  <td>{rangeDetail.vehicleDisplayName}</td>
                  <td>{rangeDetail.hours.toFixed(2)}</td>
                  <td data-intro={idx === 0 ? 'fleet-satistics-arrow' : ''}>
                    <RightDirectionArrow data={rangeDetail} />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductsList;
