import toast from 'react-hot-toast';
import { introjs } from '../IntroJs/Steps';

export const productUtilizationSteps = selectedFleet => {
  if (selectedFleet.pl !== '3') {
    return [
      {
        step: 0,
        element: '[data-intro="site-name"]',
        intro: 'Welcome to Condition Monitoring for Traction.',
      },
      {
        step: 1,
        element: `[data-intro="${selectedFleet.nm}"]`,
        intro: '',
      },
      {
        step: 2,
        element: `[data-intro="${selectedFleet.nm}"]`,
        intro:
          'Click on a fleet to explore how your traction batteries are operating.',
      },
      {
        step: 3,
        element: '[data-intro=""]',
        intro:
          'The main page provides a view on your batteries recent operation.',
      },
      {
        step: 4,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 5,
        element: '[data-intro="str-con"]',
        intro:
          'Click on the surface of the fleet utilization graph, or the legend, to filter the traction batteries based on their utilization index.',
      },
      {
        step: 6,
        element: '[data-intro="vehicle_num_click_0"]',
        tooltipPosition: 'top',
        intro:
          'The vehicles are filtered accordingly. Click on a vehicle to open the vehicle view and expand the list of installed batteries.',
      },
      {
        step: 7,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 8,
        element: '[data-intro="vehicle_product_00"]',
        intro:
          'Click on a battery to see its latest operational status, as well as information on short and long term operation and overall health. For more information on the battery-level graphs, refer to other available user guides.',
      },
      {
        step: 9,
        element: '[data-intro=""]',
        intro:
          'From Product overview page, you can navigate to Utilization chart by clicking on the utilization tab.',
      },
      {
        step: 10,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 11,
        element: '[data-intro="util-tab-title"]',
        intro:
          'You are currently on the Utilization tab, where you can view the details of Utilization for the last 30 days.',
      },
      {
        step: 12,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 13,
        element: '[data-intro="pie_graph"]',
        intro:
          'Click on the surface of the doughnut chart or the legend to filter the bar chart.',
      },
      {
        step: 14,
        element: '[data-intro="thresholds_table"]',
        intro:
          'The utilization factors legend gives information about the normal operation range for the metrics: Temperature, State of Charge, Cycles and C-rate. ',
      },
      {
        step: 15,
        element: '[data-intro="stock_graph"]',
        intro:
          "The green color of the bar indicates that the battery was operating as specified, the red color of the bar indicates that the battery's utilization thresholds were exceeded and the grey color indicates that the battery's status is unknown.",
      },
      {
        step: 16,
        element: '[data-intro="stock_graph"]',
        intro:
          "Click on the red part of the graph to see the utilization factors that contributed to the battery's stress for this day, expressed in percentages. Use the arrows in the pop-up window to navigate to the trend diagram.",
      },
      {
        step: 17,
        element: '[data-intro="main_signal_threshold"]',
        intro:
          "Tick the 'Show main signal threshold' box to show the thresholds of the main signal in the trend diagram. These thresholds allow you to easily spot instances when the utilization thresholds were exceeded.",
      },
      {
        step: 18,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 19,
        element: '[data-intro="overlay-signal"]',
        intro: 'Overlay other signals and zoom in for a higher resolution.',
      },
      {
        step: 20,
        element: '[data-intro=""]',
        intro:
          'The battery utilization guide ends here. For more information, you can refer glossary or other user guides.',
      },
    ];
  } else {
    return [
      {
        step: 0,
        element: '[data-intro="site-name"]',
        intro: 'Welcome to Condition Monitoring for Traction.',
      },

      {
        step: 1,
        element: `[data-intro="${selectedFleet.nm}"]`,
        intro: '',
      },
      {
        step: 2,
        element: `[data-intro="${selectedFleet.nm}"]`,
        intro:
          'Click on a fleet to explore how your traction batteries are operating.',
      },
      {
        step: 3,
        element: '[data-intro=""]',
        intro:
          'The main page provides a view on your batteries recent operation.',
      },
      {
        step: 4,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 5,
        element: '[data-intro="str-con"]',
        intro:
          'Click on the surface of the fleet utilization graph, or the legend, to filter the traction batteries based on their utilization index.',
      },
      {
        step: 6,
        element: '[data-intro="vehicle_num_click_0"]',
        tooltipPosition: 'top',
        intro:
          'The vehicles are filtered accordingly. Click on a vehicle to open the vehicle view and expand the list of installed batteries.',
      },
      {
        step: 7,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 8,
        element: '[data-intro="battery_num_click_0"]',
        intro:
          'Click on a battery to see its latest operational status, as well as information on short and long term operation and overall health. For more information on the battery-level graphs, refer to other available user guides.',
      },
      {
        step: 9,
        element: '[data-intro=""]',
        intro:
          'From Product overview page, you can navigate to Utilization chart by clicking on the utilization tab.',
      },
      {
        step: 10,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 11,
        element: '[data-intro="string_num_0"]',
        intro:
          'Click on a product to see its latest operational status, as well as information on short and long term operation, overall health. For more information on the product-level graphs, refer to other available user guides.',
      },
      {
        step: 12,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 13,
        element: '[data-intro="util-tab-title"]',
        intro:
          'You are currently on the Utilization tab, where you can view the details of Utilization for the last 30 days.',
      },
      {
        step: 14,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 15,
        element: '[data-intro="pie_graph"]',
        intro:
          'Click on the surface of the doughnut chart or the legend to filter the bar chart.',
      },
      {
        step: 16,
        element: '[data-intro="thresholds_table"]',
        intro:
          'The utilization factors legend gives information about the normal operation range for the metrics: Temperature, State of Charge, Cycles and C-rate. ',
      },
      {
        step: 17,
        element: '[data-intro="stock_graph"]',
        intro:
          "The green color of the bar indicates that the battery was operating as specified, the red color of the bar indicates that the battery's utilization thresholds were exceeded and the grey color indicates that the battery's status is unknown.",
      },
      {
        step: 18,
        element: '[data-intro="stock_graph"]',
        intro:
          "Click on the red part of the graph to see the utilization factors that contributed to the battery's stress for this day, expressed in percentages. Use the arrows in the pop-up window to navigate to the trend diagram.",
      },
      {
        step: 19,
        element: '[data-intro="main_signal_threshold"]',
        intro:
          "Tick the 'Show main signal threshold' box to show the thresholds of the main signal in the trend diagram. These thresholds allow you to easily spot instances when the utilization thresholds were exceeded.",
      },
      {
        step: 20,
        element: '[data-intro=""]',
        intro: '',
      },
      {
        step: 21,
        element: '[data-intro="overlay-signal"]',
        intro: 'Overlay other signals and zoom in for a higher resolution.',
      },
      {
        step: 22,
        element: '[data-intro=""]',
        intro:
          'The battery utilization guide ends here. For more information, you can refer glossary or other user guides.',
      },
    ];
  }
};

export const productUtilizationStepChange = (step, selectedFleet) => {
  try {
    if (selectedFleet.pl !== '3') {
      if (step === 0) {
        document.querySelector('[data-intro="site-name"]').click();
      }
      if (step === 1) {
        introjs.goToStep(2);
        introjs.start();
      }
      if (step === 3) {
        document.querySelector(`[data-intro="${selectedFleet.nm}"]`).click();
      }
      if (step === 4) {
        introjs.goToStep(5);
        introjs.start();
      }
      if (step === 6) {
        document.querySelector('[data-intro="vehicle_num_click_0"]').click();
      }
      if (step === 7) {
        introjs.goToStep(8);
        introjs.start();
      }
      if (step === 9) {
        document.querySelector('[data-intro="vehicle_product_00"]').click();
      }
      if (step === 10) {
        introjs.goToStep(11);
        introjs.start();
      }
      if (step === 11) {
        document.querySelector('[data-intro="util-tab-title"]').click();
      }
      if (step === 12) {
        introjs.goToStep(13);
        introjs.start();
      }
      if (step === 17) {
        document.querySelector('[data-intro="time-trend-title"]').click();
      }
      if (step === 18) {
        introjs.goToStep(19);
        introjs.start();
      }
      if (step === 20) {
        document.querySelector('[data-intro="site-name"]').click();
      }
    }
    if (selectedFleet.pl === '3') {
      if (step === 0) {
        document.querySelector('[data-intro="site-name"]').click();
      }
      if (step === 1) {
        introjs.goToStep(2);
        introjs.start();
      }
      if (step === 3) {
        document.querySelector(`[data-intro="${selectedFleet.nm}"]`).click();
      }
      if (step === 4) {
        introjs.goToStep(5);
        introjs.start();
      }
      if (step === 6) {
        document.querySelector('[data-intro="vehicle_num_click_0"]').click();
      }
      if (step === 7) {
        introjs.goToStep(8);
        introjs.start();
      }
      if (step === 9) {
        document.querySelector('[data-intro="battery_num_click_0"]').click();
      }
      if (step === 10) {
        introjs.goToStep(11);
        introjs.start();
      }
      if (step === 11) {
        document.querySelector('[data-intro="string_num_0"]').click();
      }
      if (step === 12) {
        introjs.goToStep(13);
        introjs.start();
      }
      if (step === 13) {
        document.querySelector('[data-intro="util-tab-title"]').click();
      }
      if (step === 14) {
        introjs.goToStep(15);
        introjs.start();
      }
      if (step === 19) {
        document.querySelector('[data-intro="time-trend-title"]').click();
      }
      if (step === 20) {
        introjs.goToStep(21);
        introjs.start();
      }
      if (step === 22) {
        document.querySelector('[data-intro="site-name"]').click();
      }
    }
  } catch (err) {
    toast.error('Error in user guide! Please start again.');
    introjs.exit(true);
  }
};
